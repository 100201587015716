@import './fonts';
@import './media-types';
@import './bootstrap-col';
@import './spinner';
@import './flex-grid';

.modal.fade .v1modal.modal-dialog {
  transition: transform 0.3s ease-out;
}

.v1modal {
  ::placeholder {
    color: rgb(153, 153, 153);
  }

  .divider {
    border-bottom: 1px solid #1076ab;
  }

  .textarea-fake {
    border: 1px solid #d9d9d6;
    max-height: 70vh;
    overflow-y: auto;
    padding: 5px;
  }

  .terms-of-use blockquote {
    font-size: 10pt;
  }

  blockquote,
  .quote {
    color: #7f7f7f;
    font-family: 'Georgia', 'Times', 'Times New Roman', serif;
    font-size: 20px;
    line-height: 30px;
  }
  blockquote {
    padding: 11px 22px;
    margin: 0 0 22px;
    font-size: 20px;
    border-left: 5px solid #eeeeee;
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }

  .modal-footer {
    padding: 15px;
    text-align: right;
    min-height: 0;
    border-top: 0;
    justify-content: center;
  }

  .brand-republic-red {
    color: #d80025;
  }

  .fas,
  .fa,
  .far {
    color: #1076ab;
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  .far {
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
  }

  .fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
  }

  .fa-stack-1x,
  .fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .fa-stack-2x {
    font-size: 2em;
  }

  .fa-stack-1x {
    line-height: inherit;
  }

  .fal.fa-circle.fa-stack-2x {
    top: -1px;
  }

  .fas:not(.fa-stack-2x):before {
    font-size: 1em;
  }

  .no-underline .fas {
    color: #0f76ab;
  }

  .fa-times:before {
    content: '\f00d';
  }

  .fa-sort-circle:before {
    content: '\f930';
  }

  .fa-question-circle:before {
    content: '\f059';
  }

  .fa-info-circle:before {
    content: '\f05a';
  }

  .fa-plus:before {
    content: '\f067';
  }

  .fa-search:before {
    content: '\f002';
  }

  .fa-check-circle:before {
    content: '\f058';
  }

  .fa-circle:before {
    content: '\f111';
  }

  .fa-info:before {
    content: '\f129';
  }

  .fa-exclamation-triangle:before {
    content: '\f071';
  }

  .fa-ban {
    color: #f38a00;
  }

  .fa-ban:before {
    content: '\f05e';
  }
  .fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
  }

  .form-alert .alert.alert-warning {
    background-color: #fff;
    border-color: #d80025;
    color: #d80025;
    font-weight: 400;
    padding: 5px;

    i {
      float: left;
      padding: 3px 5px 3px 3px;
    }
  }

  .alert {
    padding: 15px;
    margin-bottom: 22px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #f7f7f8;
  }

  .margin-m {
    margin: 15px;
  }

  .padding-m {
    padding: 15px;
  }

  .padding-left-m {
    padding-left: 15px;
  }
  .padding-bottom-m {
    padding-bottom: 15px;
  }

  .padding-0-horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  .margin-right-l {
    margin-right: 35px;
  }
  .margin-top-xs {
    margin-top: 7px;
  }

  .margin-top-l {
    margin-top: 35px;
  }

  .text-primary {
    color: #1076ab;
  }
  .fa-4x {
    font-size: 4em;
  }

  .link,
  *[ng-click] {
    cursor: pointer;
  }

  .bg-subtle {
    background: #f7f7f8;
  }

  .no-header-border.modal-header,
  .no-header-border .modal-header {
    border-bottom: 0;
    padding: 0.5rem;
  }

  .pull-right {
    float: right !important;
  }

  .pull-left {
    float: left !important;
  }

  .text-align-right {
    text-align: right;
  }

  .text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .btn-block {
    display: block;
    width: 100%;
  }

  .btn[disabled] {
    box-shadow: none;
    cursor: not-allowed;
  }
  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    box-shadow: none;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  .btn.btn-tertiary[disabled] {
    box-shadow: none;
    color: #4d4d4f;
    border: solid 3px #d9d9d6;
    background: #d9d9d6;
  }

  .btn-block + .btn-block {
    margin-top: 5px;
  }

  .btn {
    outline: none;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.428571429;
    border-radius: 23px;
    -webkit-user-select: none;
    user-select: none;
    text-wrap: wrap;
  }

  button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
  }

  .close {
    float: right;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
  }

  .btn-danger {
    color: #fff;
    background-color: #d80025;
    border-color: #bf0021;
  }

  .btn.btn-secondary {
    background-color: #004a7c;
    border: solid 3px #004a7c;
    color: #fff;
    outline: none;
  }

  .btn.full {
    width: 100%;
  }

  .btn.btn-tertiary {
    background-color: #007eb5;
    border: solid 2px #007eb5;
    color: #fff;
    outline: none;
  }

  .btn-default {
    background-color: #fff;
    border: solid 1px #252525;
    color: #777;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;
    border-radius: 23px;

    &:hover {
      border: solid 1px darken(#004a7c, 10%);
      box-shadow: initial;
      color: #252525;
    }

    &[disabled] {
      border: solid 1px #d9d9d6;
      color: #d9d9d6;
      cursor: not-allowed;
    }
  }

  .clear {
    clear: both;
  }

  .text-align-center,
  .text-center {
    text-align: center !important;
  }

  .text-color-gray {
    color: #777;
  }

  .text-color-alert,
  .text-danger {
    color: #d80025;
    font-size: 13px;
    font-weight: 700;
    padding-top: 3px;
  }

  .text-warning,
  .text-warning-new {
    color: #d80025 !important;
  }

  h1,
  .h1 {
    color: #252525;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.25em;
    margin: 30px 0;
  }

  h2,
  .h2 {
    color: #252525;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.25em;
    margin: 20px 0;
  }

  h2.small,
  .h2.small {
    font-size: 24px;
    line-height: 24px;
  }

  h3 {
    position: relative;
    font-size: 36px;
    z-index: 1;
  }

  h3,
  .h3 {
    color: #252525;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25em;
    margin: 10px 0;
  }

  p {
    color: #252525;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 1em;
  }

  .text-color-primary {
    color: #004a7c !important;
  }
  .text-color-primary-dark {
    color: #004a7c !important;
  }

  b,
  .text-bold,
  strong {
    font-weight: 700;
  }

  .text-italic {
    font-style: italic;
  }
  .text-xs {
    font-size: 13px;
  }
  .margin-left-xxs {
    margin-left: 4px;
  }

  .margin-bottom-l {
    margin-bottom: 35px;
  }

  .margin-bottom-m {
    margin-bottom: 15px;
  }

  .margin-bottom-s {
    margin-bottom: 11px;
  }

  .margin-left-m {
    margin-left: 15px;
  }

  input[type='text'],
  input[type='tel'],
  input[type='numeric'],
  input[type='number'],
  input[type='email'],
  input[type='search'] {
    border: solid 1px #777;
    color: #252525;
    font-size: 18px;
    height: 44px;
    line-height: 24px;
    padding-left: 10px;
  }

  .form-control,
  .form-control:focus {
    border: solid 1px #777;
    border-radius: 10px;
    color: #252525;
    font-size: 18px;
    height: 44px;
    line-height: 24px;
  }

  .form-control-feedback {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #252525;
    user-select: none;
    box-sizing: border-box;
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    position: absolute;
    right: 0;
    z-index: 2;
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    top: 4px;
    pointer-events: initial;
  }

  select.ng-touched.ng-invalid.ng-required + div.form-control-feedback:before,
  input.ng-touched.ng-invalid.ng-required + div.form-control-feedback:before,
  textarea.ng-touched.ng-invalid.ng-required
    + div.form-control-feedback:before {
    color: #d80025;
    content: '\f069';
    font-size: 18px;
    position: absolute;
    right: 10px;
  }

  select.ng-touched.ng-invalid,
  input.ng-touched.ng-invalid,
  textarea.ng-touched.ng-invalid {
    border: 2px #d80025 solid !important;
  }

  .modal-dialog .modal-content {
    border: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  @media (min-width: 768px) {
    .modal-content {
      box-shadow: 0 5px 15px #00000080;
    }
  }

  .modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px #00000080;
    outline: 0;
  }

  &.modal-dialog {
    max-width: 800px;
    // width: 100%;
    // position: absolute !important;
    // left: 50%;
    // top: 45%;
    // transform: translate(-50%, -50%) !important;
    // display: inline-block;
    // text-align: left;
    // vertical-align: middle;

    .modal-header {
      font-weight: 700;
      height: auto;
      padding: 10px 20px;
      width: 100%;
      display: block;
      min-height: 36px;

      h3,
      .h3 {
        color: #252525;
        font-family: 'Open Sans', arial, sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.25em;
        margin: 10px 0;
      }
    }

    .modal-body {
      position: relative;
      padding: 15px;
    }

    &.video-modal-player {
      .embed-responsive .embed-responsive-item,
      .embed-responsive iframe,
      .embed-responsive embed,
      .embed-responsive object,
      .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }

      .embed-responsive {
        position: relative;
        display: block;
        padding: 0;
        overflow: hidden;
      }

      .embed-responsive-16by9 {
        padding-bottom: 56.25%;
      }

      .modal-header {
        height: 28px;
      }

      .modal-body {
        position: relative;
        padding: 15px;
        background-color: #fff;
        height: auto;
      }
    }

    .product-config-pricing-breakout {
      .modal-footer {
        padding: 15px;
        text-align: right;
        min-height: 0;
      }
    }

    .textarea-fake .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

    .terms-of-use {
      font-family: Arial, sans-serif;
      font-size: 10pt;
    }
  }

  &.modal-dialog.modal-xs {
    width: 400px;

    .modal-content {
      .modal-header {
        border-bottom: 0;
      }

      .modal-body {
        margin-top: 0;
      }
    }
  }

  input.form-control + div.form-control-feedback {
    font-size: 18px;
    top: 4px;
  }

  label,
  label.control-label,
  .control-label.control-label,
  .input-helper {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    text-align: left;
    -webkit-user-select: none;
    user-select: none;
  }

  label + label,
  .control-label.control-label + .control-label.control-label {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 10px;
  }

  label,
  label.control-label,
  .control-label.control-label,
  .input-helper {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    text-align: left;
    -webkit-user-select: none;
    user-select: none;
  }

  label p,
  label.control-label p,
  .control-label.control-label p,
  .input-helper p {
    display: inline-block;
    font-size: 13px;
    line-height: 18px;
    position: relative;
    vertical-align: top;
  }

  label.disabled,
  label.control-label.disabled,
  .control-label.control-label.disabled,
  .input-helper.disabled {
    color: #d9d9d6;
  }

  @media only screen and (min-width: 768px) {
    .v1modal.modal-dialog.modal-md {
      width: 650px;
    }
  }

  .communication-preferences-modal {
    .close {
      color: #1076ab;
    }

    h4,
    .h4 {
      color: #252525;
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.5em;
      margin: 10px 0;
    }

    .btn {
      border-radius: 23px !important;
    }
  }
}

@media only screen and (min-width: 993px) {
  .v1modal.modal-dialog {
    width: 1024px;
  }

  .v1modal.modal-dialog.modal-sm {
    width: 500px;
  }
}

@media only screen and (min-width: 768px) {
  .v1modal.modal-dialog.modal-md {
    width: 650px;
  }

  hr {
    margin: 40px 0;
  }

  .v1modal.modal-dialog {
    min-height: 300px;
    width: 768px;
    max-width: 800px;
    position: absolute !important;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) !important;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

@media only screen and (min-width: 993px) {
  label.labelStyleSwitch,
  label.labelStyleSwitchDescription {
    padding: 0;
    text-align: left;
  }
}

@media only screen and (min-width: 768px) {
  .v1modal .modal-dialog.modal-md {
    width: 650px;
  }

  hr {
    margin: 40px 0;
  }

  .v1modal .modal-dialog {
    min-height: 300px;
    width: 768px;
    max-width: 800px;
    position: absolute !important;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) !important;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

@media only screen and (min-width: 993px) {
  .v1modal label.labelStyleSwitch,
  .v1modal label.labelStyleSwitchDescription {
    padding: 0;
    text-align: left;
  }

  .v1modal .modal-dialog {
    width: 1024px;
  }

  .v1modal .modal-dialog.modal-sm {
    width: 500px;
  }
}

@media only screen and (max-width: 1199px) {
  .v1modal {
    label,
    label.control-label,
    .control-label.control-label,
    .input-helper {
      margin-bottom: 10px;
    }

    label + input.form-control + div.form-control-feedback,
    label.control-label + input.form-control + div.form-control-feedback,
    .control-label.control-label
      + input.form-control
      + div.form-control-feedback,
    .input-helper + input.form-control + div.form-control-feedback {
      font-size: 18px;
      top: 33px;
    }

    &.modal-xs {
      width: 400px;
    }
  }
}

@media only screen and (min-width: 993px) {
  .v1modal {
    label,
    label.control-label,
    .control-label.control-label,
    .input-helper {
      margin: 0;
      padding: 10px 0;
    }

    label + input.form-control + div.form-control-feedback,
    label.control-label + input.form-control + div.form-control-feedback,
    .control-label.control-label
      + input.form-control
      + div.form-control-feedback,
    .input-helper + input.form-control + div.form-control-feedback {
      font-size: 18px;
      top: 44px;
    }
  }
}
