@import 'libs/rs-shared/ui-brand/src/brand';

.rsx-spinner {
  line-height: 1.3125em;
}

.rsx-spinner-wrapper {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 1em;
  border-radius: 50%;
  background-color: $light;
  -webkit-box-shadow: 0 0 2px 0 map-get($theme-colors, 'cool-gray-8c');
  box-shadow: 0 0 2px 0 map-get($theme-colors, 'cool-gray-8c');
  position: relative;

  // Material style animation adapted from https://codepen.io/finnhvman/pen/bmNdNr
  & .ring {
    width: 100%;
    height: 100%;
    -webkit-mask-image: linear-gradient(transparent 50%, $dark 50%),
      linear-gradient(to right, transparent 50%, $dark 50%);
    mask-image: linear-gradient(transparent 50%, $dark 50%),
      linear-gradient(to right, transparent 50%, $dark 50%);
    animation: pure-material-progress-circular 8s infinite
      cubic-bezier(0.3, 0.6, 1, 1);
    transform-origin: 50% 50%; // ensures smooth animation in firefox
  }

  & .ring::before {
    content: '';
    display: block;
    box-sizing: border-box;
    border: solid 0.075em transparent;
    border-top-color: $dark;
    border-radius: 50%;
    width: 100% !important;
    height: 100%;
    background-color: transparent;
    animation: pure-material-progress-circular-pseudo 1s infinite linear
      alternate;
    transform-origin: 50% 50%; // ensures smooth animation in firefox
  }
}

.rsx-spinner-page {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -999;

  &.active {
    z-index: 1040;
    display: block;
  }

  &.bg-white {
    background-color: $light;
    --bs-bg-opacity: 0.6;
  }

  & > .rsx-spinner-wrapper {
    z-index: 1050;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: relative;
  }
}

// target iOS devices to center spinner
@supports (-webkit-touch-callout: none) {
  .rsx-spinner-page {
    & > .rsx-spinner-wrapper {
      transform: translate(-50%, 400%);
    }
  }
}

.rsx-spinner-icon {
  top: 0;
  height: 1em;
  width: 1em;
  left: 0;
  position: absolute;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-2x .rsx-spinner-wrapper {
  -webkit-box-shadow: 0 0 2px 0 map-get($theme-colors, 'cool-gray-8c');
  box-shadow: 0 0 2px 0 map-get($theme-colors, 'cool-gray-8c');
}

.fa-3x .rsx-spinner-wrapper,
.fa-4x .rsx-spinner-wrapper {
  -webkit-box-shadow: 0 0 3px 0 map-get($theme-colors, 'cool-gray-8c');
  box-shadow: 0 0 3px 0 map-get($theme-colors, 'cool-gray-8c');
}

.fa-5x .rsx-spinner-wrapper {
  -webkit-box-shadow: 0 0 4px 0 map-get($theme-colors, 'cool-gray-8c');
  box-shadow: 0 0 4px 0 map-get($theme-colors, 'cool-gray-8c');
}

// Material style animation adapted from https://codepen.io/finnhvman/pen/bmNdNr
@keyframes pure-material-progress-circular {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  25% {
    transform: rotate(630deg);
  }
  37.5% {
    transform: rotate(810deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(1260deg);
  }
  62.5% {
    transform: rotate(1440deg);
    animation-timing-function: linear;
  }
  75% {
    transform: rotate(1890deg);
  }
  87.5% {
    transform: rotate(2070deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(2520deg);
  }
}

@keyframes pure-material-progress-circular-pseudo {
  0% {
    transform: rotate(-30deg);
  }
  29.4% {
    border-left-color: transparent;
  }
  29.41% {
    border-left-color: $dark;
  }
  64.7% {
    border-bottom-color: transparent;
  }
  64.71% {
    border-bottom-color: $dark;
  }
  100% {
    border-left-color: $dark;
    border-bottom-color: $dark;
    transform: rotate(225deg);
  }
}
