@import './colors';

.digital-platform-input-wrapper {
  position: relative;

  .digital-platform-input-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

    &.form {
      position: absolute;
      top: 6px;
      left: 12px;
    }

    &.base {
      position: absolute;
      top: -10px;
      left: 0px;
    }
  }

  .icon-left {
    position: absolute;
    top: 19px;
    left: 19px;

    &.active {
      top: 25px;
    }
  }

  .icon-right {
    position: absolute;
    top: 19px;
    right: 19px;
  }
}

.digital-platform-input {
  &.form {
    font-family: 'Open Sans', sans-serif;
    border-radius: 4px;
    padding: 16px 20px 16px 20px;
    height: 56px;
    box-sizing: border-box;
    border: 1px solid map-get($theme-colors, cool-gray-4c);
    resize: none;

    &.with-icon-left {
      padding-left: 48px;
    }

    &.with-icon-right {
      padding-right: 48px;
    }

    &.ng-touched.ng-invalid {
      border-color: map-get($theme-colors, orange-shade);
    }

    &.large-text {
      height: 155px;
    }

    &.active {
      padding-top: 20px;
    }

    &::placeholder {
      color: map-get($theme-colors, cool-gray-11c);
    }

    &:-ms-input-placeholder {
      color: map-get($theme-colors, cool-gray-11c);
    }

    &::-ms-input-placeholder {
      color: map-get($theme-colors, cool-gray-11c);
    }

    &:hover {
      cursor: pointer;
      border-color: map-get($theme-colors, cool-gray-8c);
    }

    &:focus {
      border-color: map-get($theme-colors, black) !important;
    }

    &.disabled {
      cursor: default !important;
      background-color: map-get($theme-colors, light-neutral);
      border-color: map-get($theme-colors, cool-gray-4c) !important;
    }
  }

  &.base {
    font-family: 'Open Sans', sans-serif;
    line-height: 28px;
    font-size: 18px;
    height: 44px;
    border-radius: 0;
    padding: 0;
    outline: 0;
    border: 0;
    border-bottom: 1px solid map-get($theme-colors, black);

    &.ng-touched.ng-invalid {
      border-color: map-get($theme-colors, orange-shade);
    }

    &::placeholder {
      color: map-get($theme-colors, cool-gray-8c);
    }

    &:-ms-input-placeholder {
      color: map-get($theme-colors, cool-gray-8c);
    }

    &::-ms-input-placeholder {
      color: map-get($theme-colors, cool-gray-8c);
    }

    &:hover {
      cursor: pointer;

      &::placeholder {
        color: map-get($theme-colors, black);
        font-weight: 600;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        font-weight: 600;
        color: map-get($theme-colors, black);
      }

      &::-ms-input-placeholder {
        font-weight: 600;
        color: map-get($theme-colors, black);
      }
    }

    &.disabled {
      cursor: default !important;
      background: none;
      border-bottom: 1px solid map-get($theme-colors, cool-gray-4c);

      &::placeholder {
        color: map-get($theme-colors, cool-gray-4c);
      }

      &:-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-4c);
      }

      &::-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-4c);
      }

      &:hover {
        cursor: pointer;

        &::placeholder {
          color: map-get($theme-colors, cool-gray-4c);
          font-weight: 400;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          font-weight: 400;
          color: map-get($theme-colors, cool-gray-4c);
        }

        &::-ms-input-placeholder {
          font-weight: 400;
          color: map-get($theme-colors, cool-gray-4c);
        }
      }
    }

    &.dark {
      background: transparent;
      color: map-get($theme-colors, white);
      border-bottom: 1px solid map-get($theme-colors, white);

      &::placeholder {
        color: map-get($theme-colors, white);
      }

      &:-ms-input-placeholder {
        color: map-get($theme-colors, white);
      }

      &::-ms-input-placeholder {
        color: map-get($theme-colors, white);
      }

      &:hover {
        cursor: pointer;

        &::placeholder {
          color: map-get($theme-colors, whitec);
          font-weight: 400;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          font-weight: 400;
          color: map-get($theme-colors, white);
        }

        &::-ms-input-placeholder {
          font-weight: 400;
          color: map-get($theme-colors, white);
        }
      }
    }

    &.neutral {
      background: map-get($theme-colors, light-neutral);
      color: map-get($theme-colors, black);
      border-bottom: 1px solid map-get($theme-colors, black);

      &::placeholder {
        color: map-get($theme-colors, cool-gray-8c);
      }

      &:-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-8c);
      }

      &::-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-8c);
      }

      &:hover {
        cursor: pointer;

        &::placeholder {
          color: map-get($theme-colors, cool-gray-8c);
          font-weight: 400;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          font-weight: 400;
          color: map-get($theme-colors, cool-gray-8c);
        }

        &::-ms-input-placeholder {
          font-weight: 400;
          color: map-get($theme-colors, cool-gray-8c);
        }
      }
    }
  }
}

.invalid-container {
  margin-top: 7.5px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .error-message {
    margin-left: 8px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: map-get($theme-colors, cool-gray-8c);
  }
}

.form-check-input[type='checkbox'] {
  border-radius: 5px;
  border: 1.5px solid #b0b1b4;
}

.form-check-input[type='checkbox']:checked {
  border-radius: 5px;
  border: 1.5px solid #004a7c;
  background-color: #004a7c;
}

.form-check-input[type='checkbox']:focus {
  box-shadow: none;
}

@mixin disabled-input-border {
  border: solid 1px map-get($theme-colors, 'cool-gray-1c');
}

@mixin input-border-black {
  border: solid 1.5px map-get($theme-colors, 'black');
}
