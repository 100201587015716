@import 'bootstrap/scss/variables';

$spacer: 2px;
$sizes: [0.5, 1, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 56];

$spacers: (
  0: 0,
);
@for $i from 1 through length($sizes) {
  $size: nth($sizes, $i);
  $spacers: map-merge(
    $spacers,
    (
      $i: $spacer * $size,
    )
  );
}
