@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), local('Open-Sans'),
    url('/assets/fonts/open-sans.woff2') format('woff2');
  font-display: swap;
}

$font-family-base: 'Open Sans', sans-serif;
$font-bold: 700;
$font-semi-bold: 600;
$font-regular: 400;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin text-fluid(
  $min-font-size,
  $max-font-size,
  $min-line-height,
  $max-line-height,
  $min-vw: 320px,
  $max-vw: 1440px
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      line-height: $min-line-height;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
        line-height: calc(
          #{$min-line-height} + #{strip-unit(
              $max-line-height - $min-line-height
            )} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
        line-height: $max-line-height;
      }
    }
  }
}

// H1
@mixin heading-1 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  letter-spacing: -1%;
  @include text-fluid(
    $min-font-size: 32px,
    $max-font-size: 48px,
    $min-line-height: 42px,
    $max-line-height: 60px
  );
}

// H2
@mixin heading-2 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  letter-spacing: -0.01em;
  @include text-fluid(
    $min-font-size: 28px,
    $max-font-size: 40px,
    $min-line-height: 38px,
    $max-line-height: 52px
  );
}

// H3
@mixin heading-3 {
  font-family: $font-family-base;
  font-weight: $font-semi-bold;
  @include text-fluid(
    $min-font-size: 24px,
    $max-font-size: 32px,
    $min-line-height: 32px,
    $max-line-height: 42px
  );
}

// H4
@mixin heading-4 {
  font-family: $font-family-base;
  font-weight: $font-semi-bold;
  @include text-fluid(
    $min-font-size: 20px,
    $max-font-size: 24px,
    $min-line-height: 32px,
    $max-line-height: 32px
  );
}

// H5
@mixin heading-5 {
  font-family: $font-family-base;
  font-weight: $font-semi-bold;
  line-height: 28px;
  @include text-fluid(
    $min-font-size: 18px,
    $max-font-size: 20px,
    $min-line-height: 28px,
    $max-line-height: 32px
  );
}

// H6
@mixin heading-6 {
  font-family: $font-family-base;
  font-weight: $font-semi-bold;
  line-height: 24px;
  font-size: 16px;
}

// H7
@mixin heading-7 {
  font-family: $font-family-base;
  font-weight: $font-semi-bold;
  line-height: 24px;
  font-size: 14px;
}

@mixin body-lg {
  font-size: 18px;
  font-weight: $font-regular;
  line-height: 28px;
}

@mixin body-md {
  font-size: 16px;
  font-weight: $font-regular;
  line-height: 24px;
}

@mixin body-sm {
  font-size: 14px;
  font-weight: $font-regular;
  line-height: 20px;
}

@mixin body-xs {
  font-size: 12px;
  font-weight: $font-regular;
  line-height: 16px;
}

@mixin text-stat {
  font-size: 76px;
  font-weight: $font-semi-bold;
  line-height: 54px;
}

@mixin text-error {
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  font-weight: $font-regular;
}

@mixin text-error-md {
  font-size: 16px;
  line-height: 32px;
  font-style: italic;
  font-weight: $font-bold;
}

@mixin eyebrow-lg {
  @include text-fluid(
    $min-font-size: 14px,
    $max-font-size: 16px,
    $min-line-height: 24px,
    $max-line-height: 24px
  );
  font-weight: $font-bold;
  letter-spacing: 0.05em;
}

@mixin eyebrow-sm {
  font-size: 12px;
  font-weight: $font-bold;
  letter-spacing: 0.05em;
  line-height: 15px;
}

@mixin subheadline-lg {
  font-size: 28px;
  font-weight: $font-regular;
  line-height: 38px;
}

@mixin subheadline-md {
  font-size: 24px;
  font-weight: $font-regular;
  line-height: 32px;
}

@mixin subheadline-sm {
  font-size: 20px;
  font-weight: $font-regular;
  line-height: 32px;
}

@mixin incrementor-lg {
  font-size: 28px;
  line-height: 36px;
  font-weight: $font-regular;
}

@mixin incrementor-sm {
  font-size: 24px;
  line-height: 28px;
  font-weight: $font-regular;
}

@mixin incrementor-xs {
  font-size: 14px;
  line-height: 20px;
  font-weight: $font-regular;
}

@mixin span-lg {
  font-size: 20px;
  line-height: 44px;
  font-weight: $font-semi-bold;
}

@mixin span-sm {
  font-size: 16px;
  line-height: 32px;
  font-weight: $font-semi-bold;
}
