/* Media types
 *
 * Imported from Bootstrap's media breakpoints
 */
/*
 * NOTE: These are legacy media types from rscom-ui.
 * They are not used in the current rs-*-ui libraries and should
 * be phased out in favor of the media queries from bootsrap.
 * PLEASE: Remove remove usage of these media types and remove this doc.
 */
$mobile-xxs: 'only screen and (max-width: 320px)'; // < $screen-xxs-min
$mobile-xs: 'only screen and (max-width: 374px)'; // < $screen-xs-min
$mobile: 'only screen and (max-width: 482px)'; // < $screen-xs-min
$tablet: 'only screen and (min-width: 483px) and (max-width: 767px)'; // $screen-xs-min - $screen-sm-min
$desktop: 'only screen and (min-width: 768px) and (max-width: 992px)'; // $screen-sm-min - $screen-lg-min
$desktop-lg: 'only screen and (min-width: 993px) and (max-width: 1199px)'; // > $screen-lg-min
$desktop-xl: 'only screen and (min-width: 1200px)'; // > $screen-lg-min

$desktop-and-down: 'only screen and (max-width: 767px)'; // > $screen-xs-min
$desktop-lg-and-down: 'only screen and (max-width: 992px)'; // > $screen-sm-min
$desktop-xl-and-down: 'only screen and (max-width: 1199px)'; // > $screen-md-min

$tablet-and-up: 'only screen and (min-width: 483px)'; // > $screen-xs-min
$desktop-and-up: 'only screen and (min-width: 768px)'; // > $screen-sm-min
$desktop-lg-and-up: 'only screen and (min-width: 993px)'; // > $screen-md-min
$desktop-xxl-and-up: 'only screen and (min-width: 1921px)'; // > $screen-lg-min
