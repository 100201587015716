.onboarding-modal {
  top: 130px;
  padding-bottom: 0;
  min-height: 530px;
  height: 530px;

  .no-header-border {
    a {
      .fas:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    padding: 0 !important;

    .on-boarding-images {
      display: block;
      height: 50%;
      width: 100%;
      padding-bottom: 10px;
      max-width: 100%;

      @media only screen and (max-width: 482px) {
        height: 100%;
        min-width: 185%;
        margin: 0 -45%;
      }
    }

    .modal-title {
      min-height: 100px;
      margin: 0;

      @media only screen and (max-width: 482px) {
        min-height: 0;
      }

      .title {
        text-align: center;

        @media only screen and (max-width: 482px) {
          padding: 5px 35px;
        }
      }

      .rslogo {
        height: 90px;

        @media only screen and (max-width: 482px) {
          height: 70px;
        }
      }
    }

    .description {
      padding: 5px 30px;
      margin-bottom: 0;
      margin-top: 5px;
    }

    .on-boarding-modal-btn {
      margin: 0 auto;

      @media only screen and (max-width: 482px) {
        width: 60%;
      }
    }
  }

  .modal-content {
    top: 100px;
  }

  @media only screen and (max-width: 482px) {
    .modal-content {
      top: 45px;
      margin: 5%;
    }

    .carousel-control {
      opacity: 1;
      width: 0;

      .icon-prev,
      .icon-next {
        top: 39%;
      }

      &.left {
        background: none;
        color: #1076ab;

        span {
          font-family: 'Open Sans', arial, sans-serif;
          font-size: 50px;
          left: 25px;
        }
      }

      &.right {
        background: none;
        color: #1076ab;

        span {
          font-family: 'Open Sans', arial, sans-serif;
          font-size: 50px;
          right: 25px;
        }
      }
    }

    .carousel-indicators {
      top: 450px;

      li {
        border-color: grey;
        margin: 10px;
        height: 12px;
        width: 12px;
        bottom: 20;
      }

      li.active {
        background-color: #00adef;
        border-color: transparent;
        margin: 10px;
        height: 12px;
        width: 12px;
      }
    }
  }
}

.carousel {
  .btn {
    border-radius: 23px !important;
    padding: 10px 20px;
  }

  .carousel-control {
    opacity: 1;
    width: 0;
    text-decoration: none;
    position: absolute;
    height: 100%;

    &-prev-icon,
    &-next-icon {
      background-image: none;
    }

    .icon-prev,
    .icon-next {
      top: 33%;
    }

    .icon-next:before,
    .icon-prev:before {
      height: auto;
      display: block;
    }

    .icon-prev:before {
      content: '‹';
    }

    .icon-next:before {
      content: '›';
    }

    &.left {
      background: none;
      color: #1076ab;

      span {
        font-family: 'Open Sans', arial, sans-serif;
        font-size: 100px;
        left: 25px;
        position: absolute;
      }
    }

    &.right {
      background: none;
      color: #1076ab;

      span {
        font-family: 'Open Sans', arial, sans-serif;
        font-size: 100px;
        right: 25px;
        position: absolute;
      }
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }

  .carousel-indicators {
    top: 450px;
    position: absolute;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    text-align: center;
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    right: 0;
    margin-top: 0;

    list-style-type: circle;
    list-style-position: inside;

    li {
      border-color: grey;
      margin: 10px;
      height: 12px;
      width: 12px;
      bottom: 20px;
      border-radius: 10px;
      text-indent: -999px;
      cursor: pointer;
      display: inline-block;
      border: 1px solid;
    }

    li.active {
      background-color: #00adef;
      border-color: transparent;
      margin: 10px;
      height: 12px;
      width: 12px;
    }
  }

  @media screen and (min-width: 768px) {
    .carousel-indicators {
      bottom: 20px;
    }
  }
}
