.auto-pay-modal {
  p {
    text-align: left;
    padding: 35px 35px 0;
  }

  @media screen and (min-width: 768px) {
    &.modal-dialog {
      max-width: 800px;
      position: absolute !important;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%) !important;
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }
}

rsx-confirmation-modal {
  .modal-footer {
    .margin-m {
      margin: 9px;
    }
  }
}
