.v1modal {
  .agreements-modal {
    .textarea-fake .container,
    .textarea-fake .container-fluid {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

    .terms-of-use {
      font-family: Arial, sans-serif;
      font-size: 10pt;
    }
    .flexbox-items.flex-col-xs-12.flex-col-md-4.flex-col-lg-4 {
      max-width: 200px;
    }
  }
}
