@import './colors';

.digital-platform-dropdown-wrapper {
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;

  .digital-platform-input-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

    &.form {
      position: absolute;
      top: 6px;
      left: 12px;
    }

    &.base {
      position: absolute;
      top: 8px;
      left: 0px;
    }
  }

  .icon-right {
    cursor: pointer;
    position: absolute;
    top: 23px;
    right: 0;
    left: auto;

    &.form {
      top: 18px;
    }
  }

  .option-panel {
    z-index: 100;
    background: map-get($theme-colors, white);
    position: absolute;
    top: 44px;
    border: 1px solid #d9d9d6;
    width: 100%;
    border-radius: 0px 0px 4px 4px;
    padding: 10px 0;

    &.form {
      top: 60px;
    }

    .option {
      cursor: pointer;
      padding: 8px 20px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      &:hover {
        background: map-get($theme-colors, light-neutral);
      }
    }
  }

  .digital-platform-input {
    &.dropdown-input {
      pointer-events: none;
    }

    &.form {
      font-family: 'Open Sans', sans-serif;
      border-radius: 4px;
      padding: 16px 20px 16px 20px;
      height: 56px;
      box-sizing: border-box;
      border: 1px solid map-get($theme-colors, cool-gray-4c);
      resize: none;
      caret-color: map-get($theme-colors, white);

      &.with-icon-right {
        padding-right: 48px;
      }

      &.ng-touched.ng-invalid {
        border-color: map-get($theme-colors, orange-shade);
      }

      &.active {
        padding-top: 20px;
      }

      &::placeholder {
        color: map-get($theme-colors, cool-gray-11c);
      }

      &:-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-11c);
      }

      &::-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-11c);
      }

      &:hover {
        cursor: pointer;
        border-color: map-get($theme-colors, cool-gray-8c);
      }

      &:focus {
        border-color: map-get($theme-colors, black) !important;
      }

      &.disabled {
        cursor: default !important;
        background-color: map-get($theme-colors, light-neutral);
        border-color: map-get($theme-colors, cool-gray-4c) !important;
      }
    }

    &.base {
      font-family: 'Open Sans', sans-serif;
      line-height: 28px;
      font-size: 18px;
      border-radius: 0;
      padding: 18px 0 0 0;
      height: 58px;
      outline: 0;
      border: 0;
      border-bottom: 1px solid map-get($theme-colors, black);
      caret-color: map-get($theme-colors, white);

      &.ng-touched.ng-invalid {
        border-color: map-get($theme-colors, orange-shade);
      }

      &::placeholder {
        color: map-get($theme-colors, cool-gray-8c);
      }

      &:-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-8c);
      }

      &::-ms-input-placeholder {
        color: map-get($theme-colors, cool-gray-8c);
      }

      &.disabled {
        cursor: default !important;
        background: none;
        border-bottom: 1px solid map-get($theme-colors, cool-gray-4c);

        &::placeholder {
          color: map-get($theme-colors, cool-gray-4c);
        }

        &:-ms-input-placeholder {
          color: map-get($theme-colors, cool-gray-4c);
        }

        &::-ms-input-placeholder {
          color: map-get($theme-colors, cool-gray-4c);
        }

        &:hover {
          cursor: pointer;

          &::placeholder {
            color: map-get($theme-colors, cool-gray-4c);
            font-weight: 400;
          }

          &:-ms-input-placeholder {
            font-weight: 400;
            color: map-get($theme-colors, cool-gray-4c);
          }

          &::-ms-input-placeholder {
            font-weight: 400;
            color: map-get($theme-colors, cool-gray-4c);
          }
        }
      }
    }
  }

  .invalid-container {
    margin-top: 7.5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .error-message {
      margin-left: 8px;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: map-get($theme-colors, cool-gray-8c);
    }
  }

  .hovered:not(.disabled) {
    cursor: pointer;

    &::placeholder {
      color: map-get($theme-colors, black) !important;
      font-weight: 600;
    }

    &:-ms-input-placeholder {
      color: map-get($theme-colors, black) !important;
      font-weight: 600;
    }

    &::-ms-input-placeholder {
      color: map-get($theme-colors, black) !important;
      font-weight: 600;
    }
  }
}
