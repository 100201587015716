@use 'sass:list';
@use 'sass:map';
@use 'sass:selector';
@use 'sass:string';

// @see https://www.figma.com/file/4Ocn3YbdHDBQtyc6HankpM/RSI-Design-System?node-id=148-1193&t=hXwPP4Hh4aVWo9tw-0
$aspect-ratios: [7, 3], [2, 1], [16, 9], [3, 2], [4, 3], [1, 1], [4, 5];

@function getOrientationName($w, $h) {
  @return if($w == $h, 'square', if($w > $h, 'landscape', 'portrait'));
}

@function getSelectors($o, $i, $w, $h) {
  $sel: '.aspect-ratio-' + $w + '-' + $h;
  @if $i == 1 {
    $sel: $sel + ', .' + $o;
  }
  @return $sel, ', .' + $o + '-' + $i;
}

.aspect-ratio {
  aspect-ratio: 1;
}

$indexes: ();

@for $i from 1 through list.length($aspect-ratios) {
  $ratio: list.nth($aspect-ratios, $i);

  $width: list.nth($ratio, 1);
  $height: list.nth($ratio, 2);

  $orientation: getOrientationName($width, $height);

  $index: map.get($indexes, $orientation);
  @if not $index {
    $index: 0;
  }
  $index: calc($index + 1);
  $indexes: map.set($indexes, $orientation, $index);

  #{getSelectors($orientation, $index, $width, $height)} {
    aspect-ratio: string.unquote($width + '/' + $height);
  }
}
