mrx-update-billing-phone-modal,
mrx-update-account-info {
  .btn.btn-tertiary {
    border-radius: 23px;
  }

  .alert.alert-danger {
    background-color: #fff;
    border-color: #e8800d;
    color: #004a7c;
  }

  .alert {
    padding: 15px;
    margin-bottom: 22px;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .margin-top-m {
    margin-top: 15px;
  }

  .form-group {
    margin-bottom: 15px;
  }
}
