$brand-gray: #777 !default;
$brand-white: #fff;
$brand-gray-input: #b0b1b4;

.rsx-spinner {
  line-height: 1.3125em;
}

.rsx-spinner-wrapper {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 1em;
  border-radius: 50%;
  background-color: $white;
  -webkit-box-shadow: 0 0 2px 0 $brand-gray;
  box-shadow: 0 0 2px 0 $brand-gray;
  position: relative;
}

.rsx-spinner-page {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;
}

.rsx-spinner-page.bg-white {
  background-color: $white;
}

.rsx-spinner-page > .backdrop {
  background-color: $white;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;
}

.rsx-spinner-page > .rsx-spinner-wrapper {
  z-index: 1050;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: relative;
}

.rsx-spinner-page.active {
  opacity: 1;
  z-index: 1040;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
}

.rsx-spinner-page.active > .backdrop {
  opacity: 0.5;
}

.rsx-spinner-icon,
.loading-circle {
  top: 0;
  height: 1em;
  width: 1em;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.rsx-spinner-icon {
  padding: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-circle {
  animation: rotating 2s linear infinite;
  transform-origin: center;
  position: absolute;
  top: 0;
  left: 0;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-2x .rsx-spinner-wrapper {
  -webkit-box-shadow: 0 0 2px 0 $brand-gray;
  box-shadow: 0 0 2px 0 $brand-gray;
}

.fa-3x .rsx-spinner-wrapper,
.fa-4x .rsx-spinner-wrapper {
  -webkit-box-shadow: 0 0 3px 0 $brand-gray;
  box-shadow: 0 0 3px 0 $brand-gray;
}

.fa-5x .rsx-spinner-wrapper {
  -webkit-box-shadow: 0 0 4px 0 $brand-gray;
  box-shadow: 0 0 4px 0 $brand-gray;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
