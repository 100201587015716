.product-carousel {
  .product-carousel__viewer .product-carousel__left-arrow i.fas,
  .product-carousel__viewer .product-carousel__right-arrow i.fas {
    color: #fff;
    font-size: 28px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .product-carousel__viewer .product-carousel__right-arrow {
    right: 0;
  }
  .product-carousel__viewer .product-carousel__left-arrow,
  .product-carousel__viewer .product-carousel__right-arrow {
    background-color: #0000004d;
    height: 34px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    z-index: 1;
  }

  .product-carousel__selected-image {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    overflow: hidden;
  }

  .product-carousel__left-arrow,
  .product-carousel__right-arrow {
    background-color: hsla(0, 0%, 0%, 0.3);
    height: 34px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 24px;
    z-index: 1;

    &:focus,
    i.fas:focus {
      outline: 0;
    }

    i.fas {
      color: $white;
      font-size: 28px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @media #{$tablet-and-up} {
      display: none;
    }

    &.hover {
      display: block; //IE Support Class
    }
  }
}

@media only screen and (min-width: 483px) {
  .product-carousel .product-carousel__viewer .product-carousel__left-arrow,
  .product-carousel .product-carousel__viewer .product-carousel__right-arrow {
    display: none;
  }
}
