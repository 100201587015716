@import './colors';

digital-platform-rsx-a {
  &:hover {
    font-weight: bold !important;
  }

  a.text-color-white {
    color: $white;

    @media (max-width: 991px) {
      &:focus {
        color: $gray-300;
      }

      &:hover {
        font-weight: 400;
      }
    }
  }

  a.text-color-black {
    color: $black;
  }
}

.link-hover-underline {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}
