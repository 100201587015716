/**
 * TEMPORARY: CSS spacing classes for layout
 * FIXME: Refactor and update to standardize using bootstrap class mixins and match CMS
 */

$spacing-xxs: 4px;
$spacing-xs: 7px;
$spacing-s: 12px;
$spacing-m: 15px;
$spacing-m-plus: 20px;
$spacing-m-plus-plus: 24px;
$spacing-l-minus: 30px;
$spacing-l: 35px;
$spacing-l-plus: 40px;
$spacing-xl: 50px;
$spacing-xxl: 70px;

$page-content-width: 1000px;

$cms-spacers: (
  0: 0,
  xxs: $spacing-xxs,
  xs: $spacing-xs,
  s: $spacing-s,
  m: $spacing-m,
  m-plus: $spacing-m-plus,
  m-plus-plus: $spacing-m-plus-plus,
  l-minus: $spacing-l-minus,
  l: $spacing-l,
  l-plus: $spacing-l-plus,
  xl: $spacing-xl,
  xxl: $spacing-xxl,
  auto: auto,
);

$cms-spacer-sizes: map-keys($cms-spacers);

/// Generic Spacing Mixin
///
/// @example
///   @include create-spacing(padding, m); // => { padding: 15px }
///   @include create-spacing(margin, xl, bottom); // => { margin-bottom-xl: 50px }
///   @include create-spacing(margin, auto, left); // => { margin-left: auto }
@mixin create-spacing($prop, $size, $side: null) {
  $value: map-get($cms-spacers, $size);

  @if $value {
    @if $side {
      #{$prop}-#{$side}: $value;
    } @else {
      #{$prop}: $value;
    }
  } @else {
    @error 'Unknown size #{$size}. Must be one of #{$cms-spacer-sizes}';
  }
}

// Margin Mixins
@mixin margin-xs {
  @include create-spacing(margin, xs);
}

@mixin margin-top-xs {
  @include create-spacing(margin, xs, top);
}

@mixin margin-top-s {
  @include create-spacing(margin, s, top);
}

@mixin margin-top-m {
  @include create-spacing(margin, m, top);
}

@mixin margin-top-l {
  @include create-spacing(margin, l, top);
}

@mixin margin-bottom-xs {
  @include create-spacing(margin, xs, bottom);
}

@mixin margin-left-0 {
  @include create-spacing(margin, 0, left);
}

@mixin margin-right-0 {
  @include create-spacing(margin, 0, right);
}

@mixin margin-right-s {
  @include create-spacing(margin, s, right);
}

@mixin margin-right-xs {
  @include create-spacing(margin, xs, right);
}
// End Margin Mixins

// Padding Mixins
@mixin padding-s {
  @include create-spacing(padding, s);
}

@mixin padding-m {
  @include create-spacing(padding, m);
}

@mixin padding-l {
  @include create-spacing(padding, l);
}

@mixin padding-bottom-l {
  @include create-spacing(padding, l, bottom);
}
// End Padding Mixins

/// Non-responsive semantic spacing utility classes
///
/// The classes are named using the format `{property}-{side}-{size}`.
///
/// Where *property* is one of:
/// - `margin` - for classes that set `margin`
/// - `padding` - for classes that set `padding`
///
/// Where *side* is one of:
/// - `top` - for classes that set `margin-top` or `padding-top`
/// - `bottom` - for classes that set `margin-bottom` or `padding-bottom`
/// - `left` - for classes that set `margin-left` or `padding-left`
/// - `right` - for classes that set `margin-right` or `padding-right`
/// - blank - for classes that set a `margin` or `padding` on all 4 sides of the element
///
/// Where *size* is one of:
/// - `0` - for classes that eliminate the `margin` or `padding` by setting it to `0`
/// - `xxs` - (by default) for classes that set the `margin` or `padding` to `4px`
/// - `xs` - (by default) for classes that set the `margin` or `padding` to `7px`
/// - `s` - (by default) for classes that set the `margin` or `padding` to `12px`
/// - `m` - (by default) for classes that set the `margin` or `padding` to `15px`
/// - `l` - (by default) for classes that set the `margin` or `padding` to `35px`
/// - `xl` - (by default) for classes that set the `margin` or `padding` to `50px`
/// - `xxl` - (by default) for classes that set the `margin` or `padding` to `70px`
/// - `auto` - for classes that set the `margin` to auto
///
/// @example
///   .padding-0 {
///     padding: 0;
///   }
///
///   .padding-top-xxs {
///     padding-top: 4px;
///   }
///
///   .margin-left-l {
///     margin-left: 35px;
///   }
///
///   .margin-auto {
///     margin: auto;
///   }
@each $prop in (margin, padding) {
  @each $size in $cms-spacer-sizes {
    // Skip `auto` size for padding
    @if not($prop == padding and $size == auto) {
      .#{$prop}-#{$size} {
        @include create-spacing($prop, $size);
      }

      @each $position in (top, bottom, left, right) {
        .#{$prop}-#{$position}-#{$size} {
          @include create-spacing($prop, $size, $position);
        }
      }
    }
  }
}

.btn {
  text-transform: uppercase;

  &.btn-center {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Hero Carousel Text Styles */
.digital-platform-hero-carousel {
  .carousel-above-header {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #ffffff !important;
  }
  .carousel-header {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
  }
  .carousel-header-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff !important;
  }
  .circularity-header {
    font-size: 32px;
    font-weight: 700;
  }
  .circularity-info {
    padding-top: 30px;
    font-size: 24px;
  }

  @media only screen and (min-width: 576px) {
    .carousel-above-header {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      text-transform: capitalize;
      color: #ffffff !important;
    }
    .carousel-header {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
    }
    .carousel-header-description {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
  }

  @media (max-width: 1200px) {
    .circularity-header {
      font-size: 28px;
    }
    .circularity-info {
      font-size: 14px;
      padding-top: 15px !important;
    }
  }
}
