.v1modal {
  .flex-row {
    margin-left: -2%;
    margin-right: -2%;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-vcenter {
    align-items: center;
  }
  .flex-center {
    justify-content: center;
  }

  .flex-grid .xs-flex-1col {
    flex-basis: 96%;
    margin: 0 2% 20px;
  }

  .flex-grid .xs-flex-2col {
    flex-basis: 46%;
    margin: 0 2% 20px;
  }

  .flex-grid .xs-flex-3col {
    flex-basis: 29.3333333333%;
    margin: 0 2% 20px;
  }

  .flex-grid .xs-flex-4col {
    flex-basis: 21%;
    margin: 0 2% 20px;
  }

  .flex-grid .xs-flex-5col {
    flex-basis: 16%;
    margin: 0 2% 20px;
  }

  .flex-grid .xs-flex-6col {
    flex-basis: 12.6666666667%;
    margin: 0 2% 20px;
  }

  .flex-grid.no-gutters .xs-flex-1col {
    flex-basis: 100%;
    margin: 0;
  }

  .flex-grid.no-gutters .xs-flex-2col {
    flex-basis: 50%;
    margin: 0;
  }

  .flex-grid.no-gutters .xs-flex-3col {
    flex-basis: 33.3333333333%;
    margin: 0;
  }

  .flex-grid.no-gutters .xs-flex-4col {
    flex-basis: 25%;
    margin: 0;
  }

  .flex-grid.no-gutters .xs-flex-5col {
    flex-basis: 20%;
    margin: 0;
  }

  .flex-grid.no-gutters .xs-flex-6col {
    flex-basis: 16.6666666667%;
    margin: 0;
  }

  .flex-grow {
    flex-grow: 1;
  }

  @media only screen and (max-width: 482px) {
    .flex-grid .xxs-flex-1col {
      flex-basis: 96%;
      margin: 0 2% 20px;
    }

    .flex-grid .xxs-flex-2col {
      flex-basis: 46%;
      margin: 0 2% 20px;
    }

    .flex-grid .xxs-flex-3col {
      flex-basis: 29.3333333333%;
      margin: 0 2% 20px;
    }

    .flex-grid .xxs-flex-4col {
      flex-basis: 21%;
      margin: 0 2% 20px;
    }

    .flex-grid .xxs-flex-5col {
      flex-basis: 16%;
      margin: 0 2% 20px;
    }

    .flex-grid .xxs-flex-6col {
      flex-basis: 12.6666666667%;
      margin: 0 2% 20px;
    }

    .flex-grid.no-gutters > .xxs-flex-1col {
      flex-basis: 100%;
      margin: 0;
    }

    .flex-grid.no-gutters > .xxs-flex-2col {
      flex-basis: 50%;
      margin: 0;
    }

    .flex-grid.no-gutters > .xxs-flex-3col {
      flex-basis: 33.3333333333%;
      margin: 0;
    }

    .flex-grid.no-gutters > .xxs-flex-4col {
      flex-basis: 25%;
      margin: 0;
    }

    .flex-grid.no-gutters > .xxs-flex-5col {
      flex-basis: 20%;
      margin: 0;
    }

    .flex-grid.no-gutters > .xxs-flex-6col {
      flex-basis: 16.6666666667%;
      margin: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    .flex-grid .sm-flex-1col {
      flex-basis: 98%;
      margin: 0 1% 20px;
    }

    .flex-grid .sm-flex-2col {
      flex-basis: 48%;
      margin: 0 1% 20px;
    }

    .flex-grid .sm-flex-3col {
      flex-basis: 31.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .sm-flex-4col {
      flex-basis: 23%;
      margin: 0 1% 20px;
    }

    .flex-grid .sm-flex-5col {
      flex-basis: 18%;
      margin: 0 1% 20px;
    }

    .flex-grid .sm-flex-6col {
      flex-basis: 14.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid.no-gutters > .sm-flex-1col {
      flex-basis: 100%;
      margin: 0;
    }

    .flex-grid.no-gutters > .sm-flex-2col {
      flex-basis: 50%;
      margin: 0;
    }

    .flex-grid.no-gutters > .sm-flex-3col {
      flex-basis: 33.3333333333%;
      margin: 0;
    }

    .flex-grid.no-gutters > .sm-flex-4col {
      flex-basis: 25%;
      margin: 0;
    }

    .flex-grid.no-gutters > .sm-flex-5col {
      flex-basis: 20%;
      margin: 0;
    }

    .flex-grid.no-gutters > .sm-flex-6col {
      flex-basis: 16.6666666667%;
      margin: 0;
    }
  }

  @media only screen and (min-width: 993px) {
    .flex-grid .md-flex-1col {
      flex-basis: 98%;
      margin: 0 1% 20px;
    }

    .flex-grid .md-flex-2col {
      flex-basis: 48%;
      margin: 0 1% 20px;
    }

    .flex-grid .md-flex-3col {
      flex-basis: 31.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .md-flex-4col {
      flex-basis: 23%;
      margin: 0 1% 20px;
    }

    .flex-grid .md-flex-5col {
      flex-basis: 18%;
      margin: 0 1% 20px;
    }

    .flex-grid .md-flex-6col {
      flex-basis: 14.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid.no-gutters > .md-flex-1col {
      flex-basis: 100%;
      margin: 0;
    }

    .flex-grid.no-gutters > .md-flex-2col {
      flex-basis: 50%;
      margin: 0;
    }

    .flex-grid.no-gutters > .md-flex-3col {
      flex-basis: 33.3333333333%;
      margin: 0;
    }

    .flex-grid.no-gutters > .md-flex-4col {
      flex-basis: 25%;
      margin: 0;
    }

    .flex-grid.no-gutters > .md-flex-5col {
      flex-basis: 20%;
      margin: 0;
    }

    .flex-grid.no-gutters > .md-flex-6col {
      flex-basis: 16.6666666667%;
      margin: 0;
    }
  }

  .flex-grid .flex-col-xs-1 {
    flex-basis: 4.3333333333%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-2 {
    flex-basis: 12.6666666667%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-3 {
    flex-basis: 21%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-4 {
    flex-basis: 29.3333333333%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-5 {
    flex-basis: 37.6666666667%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-6 {
    flex-basis: 46%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-7 {
    flex-basis: 54.3333333333%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-8 {
    flex-basis: 62.6666666667%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-9 {
    flex-basis: 71%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-10 {
    flex-basis: 79.3333333333%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-11 {
    flex-basis: 87.6666666667%;
    margin: 0 2% 20px;
  }

  .flex-grid .flex-col-xs-12 {
    flex-basis: 96%;
    margin: 0 2% 20px;
  }

  .flex-grid.no-gutters .flex-col-xs-1 {
    flex-basis: 8.3333333333%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-2 {
    flex-basis: 16.6666666667%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-3 {
    flex-basis: 25%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-4 {
    flex-basis: 33.3333333333%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-5 {
    flex-basis: 41.6666666667%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-6 {
    flex-basis: 50%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-7 {
    flex-basis: 58.3333333333%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-8 {
    flex-basis: 66.6666666667%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-9 {
    flex-basis: 75%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-10 {
    flex-basis: 83.3333333333%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-11 {
    flex-basis: 91.6666666667%;
    margin-bottom: 0;
  }

  .flex-grid.no-gutters .flex-col-xs-12 {
    flex-basis: 100%;
    margin-bottom: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-1 {
    flex-basis: 8.3333333333%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-2 {
    flex-basis: 16.6666666667%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-3 {
    flex-basis: 25%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-4 {
    flex-basis: 33.3333333333%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-5 {
    flex-basis: 41.6666666667%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-6 {
    flex-basis: 50%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-7 {
    flex-basis: 58.3333333333%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-8 {
    flex-basis: 66.6666666667%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-9 {
    flex-basis: 75%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-10 {
    flex-basis: 83.3333333333%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-11 {
    flex-basis: 91.6666666667%;
    margin: 0;
  }

  .flexbox-items.full-width-item.flex-col-xs-12 {
    flex-basis: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 768px) {
    .flex-grid .flex-col-sm-1 {
      flex-basis: 6.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-2 {
      flex-basis: 14.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-3 {
      flex-basis: 23%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-4 {
      flex-basis: 31.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-5 {
      flex-basis: 39.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-6 {
      flex-basis: 48%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-7 {
      flex-basis: 56.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-8 {
      flex-basis: 64.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-9 {
      flex-basis: 73%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-10 {
      flex-basis: 81.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-11 {
      flex-basis: 89.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-sm-12 {
      flex-basis: 98%;
      margin: 0 1% 20px;
    }

    .flex-grid.no-gutters > .flex-col-sm-1 {
      flex-basis: 8.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-2 {
      flex-basis: 16.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-3 {
      flex-basis: 25%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-4 {
      flex-basis: 33.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-5 {
      flex-basis: 41.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-6 {
      flex-basis: 50%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-7 {
      flex-basis: 58.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-8 {
      flex-basis: 66.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-9 {
      flex-basis: 75%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-10 {
      flex-basis: 83.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-11 {
      flex-basis: 91.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-sm-12 {
      flex-basis: 100%;
      margin-bottom: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-1 {
      flex-basis: 8.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-2 {
      flex-basis: 16.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-3 {
      flex-basis: 25%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-4 {
      flex-basis: 33.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-5 {
      flex-basis: 41.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-6 {
      flex-basis: 50%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-7 {
      flex-basis: 58.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-8 {
      flex-basis: 66.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-9 {
      flex-basis: 75%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-10 {
      flex-basis: 83.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-11 {
      flex-basis: 91.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-sm-12 {
      flex-basis: 100%;
      margin: 0;
    }
  }

  @media only screen and (min-width: 993px) {
    .flex-grid .flex-col-md-1 {
      flex-basis: 6.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-2 {
      flex-basis: 14.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-3 {
      flex-basis: 23%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-4 {
      /* flex-basis:31.3333333333%; */
      /* margin:0 1% 20px; */
    }

    .flex-grid .flex-col-md-5 {
      flex-basis: 39.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-6 {
      flex-basis: 48%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-7 {
      flex-basis: 56.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-8 {
      flex-basis: 64.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-9 {
      flex-basis: 73%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-10 {
      flex-basis: 81.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-11 {
      flex-basis: 89.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-md-12 {
      flex-basis: 98%;
      margin: 0 1% 20px;
    }

    .flex-grid.no-gutters > .flex-col-md-1 {
      flex-basis: 8.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-2 {
      flex-basis: 16.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-3 {
      flex-basis: 25%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-4 {
      flex-basis: 33.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-5 {
      flex-basis: 41.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-6 {
      flex-basis: 50%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-7 {
      flex-basis: 58.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-8 {
      flex-basis: 66.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-9 {
      flex-basis: 75%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-10 {
      flex-basis: 83.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-11 {
      flex-basis: 91.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-md-12 {
      flex-basis: 100%;
      margin-bottom: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-1 {
      flex-basis: 8.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-2 {
      flex-basis: 16.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-3 {
      flex-basis: 25%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-4 {
      flex-basis: 33.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-5 {
      flex-basis: 41.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-6 {
      flex-basis: 50%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-7 {
      flex-basis: 58.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-8 {
      flex-basis: 66.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-9 {
      flex-basis: 75%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-10 {
      flex-basis: 83.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-11 {
      flex-basis: 91.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-md-12 {
      flex-basis: 100%;
      margin: 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    .flex-grid .flex-col-lg-1 {
      flex-basis: 6.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-2 {
      flex-basis: 14.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-3 {
      flex-basis: 23%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-4 {
      flex-basis: 31.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-5 {
      flex-basis: 39.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-6 {
      flex-basis: 48%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-7 {
      flex-basis: 56.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-8 {
      /* flex-basis:64.6666666667%; */
      /* margin:0 1% 20px; */
    }

    .flex-grid .flex-col-lg-9 {
      flex-basis: 73%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-10 {
      flex-basis: 81.3333333333%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-11 {
      flex-basis: 89.6666666667%;
      margin: 0 1% 20px;
    }

    .flex-grid .flex-col-lg-12 {
      flex-basis: 98%;
      margin: 0 1% 20px;
    }

    .flex-grid.no-gutters > .flex-col-lg-1 {
      flex-basis: 8.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-2 {
      flex-basis: 16.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-3 {
      flex-basis: 25%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-4 {
      flex-basis: 33.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-5 {
      flex-basis: 41.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-6 {
      flex-basis: 50%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-7 {
      flex-basis: 58.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-8 {
      flex-basis: 66.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-9 {
      flex-basis: 75%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-10 {
      flex-basis: 83.3333333333%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-11 {
      flex-basis: 91.6666666667%;
      margin-bottom: 0;
    }

    .flex-grid.no-gutters > .flex-col-lg-12 {
      flex-basis: 100%;
      margin-bottom: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-1 {
      flex-basis: 8.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-2 {
      flex-basis: 16.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-3 {
      flex-basis: 25%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-4 {
      flex-basis: 33.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-5 {
      flex-basis: 41.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-6 {
      flex-basis: 50%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-7 {
      flex-basis: 58.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-8 {
      flex-basis: 66.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-9 {
      flex-basis: 75%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-10 {
      flex-basis: 83.3333333333%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-11 {
      flex-basis: 91.6666666667%;
      margin: 0;
    }

    .flex-grid .flexbox-items.full-width-item.flex-col-lg-12 {
      flex-basis: 100%;
      margin: 0;
    }
  }
}
