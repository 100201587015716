rsx-info-tooltip {
  color: red;
  .info-tooltip {
    &,
    &.a,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &,
    &:active,
    &:focus,
    &:visited {
      color: #1076ab;
    }

    &:hover {
      color: #004a7c;
    }
  }

  &.gray .info-tooltip {
    &,
    &.a,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &,
    &:active,
    &:focus,
    &:visited {
      color: #252525;
    }

    &:hover {
      color: #252525;
    }
  }

  &.dark-blue .info-tooltip {
    &,
    &.a,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &,
    &:active,
    &:focus,
    &:visited {
      color: #004a7c;
    }

    &:hover {
      color: #004a7c;
    }
  }

  &.white .info-tooltip {
    @media only screen and (max-width: 482px) {
      &,
      &.a,
      &:hover,
      &:focus {
        text-decoration: none;
      }

      &,
      &:active,
      &:focus,
      &:visited {
        color: #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .fa-stack {
    &,
    & .fa-stack-1x {
      margin-bottom: -9px;
      vertical-align: top;
    }

    &.text-md,
    &.text-lg,
    &.text-xl,
    &.text-xxl {
      .fa-stack-1x {
        line-height: 2em;
      }
    }
  }
}

.tooltip {
  margin-top: -5px;
  position: absolute;
  width: 330px;

  @media only screen and (max-width: 482px) {
    left: 0 !important;
    margin-top: -5px;
    position: absolute;
    right: 0 !important;
    white-space: normal;
    width: 100%;
  }

  &.in {
    opacity: 1;
  }

  &.bottom .tooltip-arrow {
    border-width: 1px 1px 0;
    margin-left: -5px;
    top: 1px;
    transform: rotate(45deg);
  }

  &.top .tooltip-arrow {
    border-top-color: #1076ab;
    border-width: 1px 1px 0;
    bottom: 1px;
    margin-left: -5px;
    transform: rotate(225deg);
  }

  .tooltip-arrow {
    background-color: #fff;
    border-bottom-color: transparent;
    border-left-color: #1076ab;
    border-radius: 1px;
    border-right-color: transparent;
    border-top-color: #1076ab;
    border-width: 1px;
    height: 10px;
    left: 50%;
    margin-left: -15px;
    width: 10px;
    display: none; // added this to to hide the arrow because of a strict time deadline and problems with bootstrap version conflicts

    &::before {
      position: absolute;
      content: '';
      border-color: transparent;
      border-style: solid;
    }

    @media only screen and (max-width: 482px) {
      display: none;
    }
  }

  .tooltip-inner {
    background-color: #fff;
    border: 1px solid #1076ab;
    border-radius: 1px;
    box-sizing: border-box;
    color: #000;
    font-size: 13px;
    line-height: 18px;
    max-width: 100%;
    min-width: 80px;
    padding: 15px 20px;
    text-align: left;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul,
    li {
      clear: both;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      white-space: nowrap;
    }
  }
}

.tooltip-alignment {
  margin-top: 26px;
}

// // Base class
// .tooltip {
//   $spacer: 1rem !default;
//   $prefix: 'bs';
//   $zindex-tooltip: 1080 !default;
//   $tooltip-font-size: 13px !default;
//   $tooltip-max-width: 200px !default;
//   $tooltip-color: var(--#{$prefix}body-bg) !default;
//   $tooltip-bg: var(--#{$prefix}emphasis-color) !default;
//   $tooltip-border-radius: var(--#{$prefix}border-radius) !default;
//   $tooltip-opacity: 0.9 !default;
//   $tooltip-padding-y: $spacer * 0.25 !default;
//   $tooltip-padding-x: $spacer * 0.5 !default;
//   $tooltip-margin: null !default; // TODO: remove this in v6

//   $tooltip-arrow-width: 0.8rem !default;
//   $tooltip-arrow-height: 0.4rem !default;
//   // fusv-disable
//   $tooltip-arrow-color: null !default; // Deprecated in Bootstrap 5.2.0 for CSS variables
//   // fusv-enable
//   // scss-docs-end tooltip-variables

//   // Form tooltips must come after regular tooltips
//   // scss-docs-start tooltip-feedback-variables
//   $form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
//   $form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
//   $form-feedback-tooltip-font-size: $tooltip-font-size !default;
//   $form-feedback-tooltip-line-height: null !default;
//   $form-feedback-tooltip-opacity: $tooltip-opacity !default;
//   $form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

//   // scss-docs-start tooltip-css-vars
//   --#{$prefix}tooltip-zindex: #{$zindex-tooltip};
//   --#{$prefix}tooltip-max-width: #{$tooltip-max-width};
//   --#{$prefix}tooltip-padding-x: #{$tooltip-padding-x};
//   --#{$prefix}tooltip-padding-y: #{$tooltip-padding-y};
//   --#{$prefix}tooltip-margin: #{$tooltip-margin};
//   @include rfs($tooltip-font-size, --#{$prefix}tooltip-font-size);
//   --#{$prefix}tooltip-color: #{$tooltip-color};
//   --#{$prefix}tooltip-bg: #{$tooltip-bg};
//   --#{$prefix}tooltip-border-radius: #{$tooltip-border-radius};
//   --#{$prefix}tooltip-opacity: #{$tooltip-opacity};
//   --#{$prefix}tooltip-arrow-width: #{$tooltip-arrow-width};
//   --#{$prefix}tooltip-arrow-height: #{$tooltip-arrow-height};
//   // scss-docs-end tooltip-css-vars

//   z-index: var(--#{$prefix}tooltip-zindex);
//   display: block;
//   margin: var(--#{$prefix}tooltip-margin);
//   @include deprecate('`$tooltip-margin`', 'v5', 'v5.x', true);
//   // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
//   // So reset our font and text properties to avoid inheriting weird values.
//   @include reset-text();
//   @include font-size(var(--#{$prefix}tooltip-font-size));
//   // Allow breaking very long words so they don't overflow the tooltip's bounds
//   word-wrap: break-word;
//   opacity: 0;

//   &.show {
//     opacity: var(--#{$prefix}tooltip-opacity);
//   }

//   .tooltip-arrow {
//     display: block;
//     width: var(--#{$prefix}tooltip-arrow-width);
//     height: var(--#{$prefix}tooltip-arrow-height);

//     &::before {
//       position: absolute;
//       content: '';
//       border-color: transparent;
//       border-style: solid;
//     }
//   }
// }

// .bs-tooltip-top .tooltip-arrow {
//   bottom: calc(
//     -1 * var(--#{$prefix}tooltip-arrow-height)
//   ); // stylelint-disable-line function-disallowed-list

//   &::before {
//     top: -1px;
//     border-width: var(--#{$prefix}tooltip-arrow-height)
//       calc(var(--#{$prefix}tooltip-arrow-width) * 0.5) 0; // stylelint-disable-line function-disallowed-list
//     border-top-color: var(--#{$prefix}tooltip-bg);
//   }
// }

// /* rtl:begin:ignore */
// .bs-tooltip-end .tooltip-arrow {
//   left: calc(
//     -1 * var(--#{$prefix}tooltip-arrow-height)
//   ); // stylelint-disable-line function-disallowed-list
//   width: var(--#{$prefix}tooltip-arrow-height);
//   height: var(--#{$prefix}tooltip-arrow-width);

//   &::before {
//     right: -1px;
//     border-width: calc(var(--#{$prefix}tooltip-arrow-width) * 0.5)
//       var(--#{$prefix}tooltip-arrow-height)
//       calc(var(--#{$prefix}tooltip-arrow-width) * 0.5) 0; // stylelint-disable-line function-disallowed-list
//     border-right-color: var(--#{$prefix}tooltip-bg);
//   }
// }

// /* rtl:end:ignore */

// .bs-tooltip-bottom .tooltip-arrow {
//   top: calc(
//     -1 * var(--#{$prefix}tooltip-arrow-height)
//   ); // stylelint-disable-line function-disallowed-list

//   &::before {
//     bottom: -1px;
//     border-width: 0 calc(var(--#{$prefix}tooltip-arrow-width) * 0.5)
//       var(--#{$prefix}tooltip-arrow-height); // stylelint-disable-line function-disallowed-list
//     border-bottom-color: var(--#{$prefix}tooltip-bg);
//   }
// }

// /* rtl:begin:ignore */
// .bs-tooltip-start .tooltip-arrow {
//   right: calc(
//     -1 * var(--#{$prefix}tooltip-arrow-height)
//   ); // stylelint-disable-line function-disallowed-list
//   width: var(--#{$prefix}tooltip-arrow-height);
//   height: var(--#{$prefix}tooltip-arrow-width);

//   &::before {
//     left: -1px;
//     border-width: calc(var(--#{$prefix}tooltip-arrow-width) * 0.5) 0
//       calc(var(--#{$prefix}tooltip-arrow-width) * 0.5)
//       var(--#{$prefix}tooltip-arrow-height); // stylelint-disable-line function-disallowed-list
//     border-left-color: var(--#{$prefix}tooltip-bg);
//   }
// }

// /* rtl:end:ignore */

// .bs-tooltip-auto {
//   &[data-popper-placement^='top'] {
//     @extend .bs-tooltip-top;
//   }
//   &[data-popper-placement^='right'] {
//     @extend .bs-tooltip-end;
//   }
//   &[data-popper-placement^='bottom'] {
//     @extend .bs-tooltip-bottom;
//   }
//   &[data-popper-placement^='left'] {
//     @extend .bs-tooltip-start;
//   }
// }

// // Wrapper for the tooltip content
// .tooltip-inner {
//   max-width: var(--#{$prefix}tooltip-max-width);
//   padding: var(--#{$prefix}tooltip-padding-y) var(--#{$prefix}tooltip-padding-x);
//   color: var(--#{$prefix}tooltip-color);
//   text-align: center;
//   background-color: var(--#{$prefix}tooltip-bg);
//   @include border-radius(var(--#{$prefix}tooltip-border-radius));
// }
