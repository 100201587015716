.add-non-owner-modal {
  h3,
  .h3 {
    color: #252525;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25em;
    margin: 10px 0;
  }

  .btn {
    border-radius: 23px;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    outline: none;
    padding: 10px 20px;
    text-decoration: none;
    white-space: inherit;
  }

  a {
    color: #489fdf;
    font-weight: 400;
    text-decoration: underline;
    -webkit-text-size-adjust: inherit;
    text-size-adjust: inherit;
    line-height: 20px;
  }

  &.ui-state-mr {
    .modal-body {
      margin-top: 0;

      .contacts-list > .row.checked {
        background-color: #e6f6fd;
      }

      .contacts-list > .row > div:not(.cb-col) {
        line-height: 28px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .contacts-list {
        max-height: 300px;
        overflow-y: auto;
      }
    }

    .search-input-container {
      position: relative;

      input {
        width: 100%;
        font-size: 13px;
        margin-right: 0;
        padding-right: 35px;
      }

      i {
        color: #777;
        display: inline-block;
        position: absolute;
        right: 8px;
        top: 15px;
        width: 15px;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 20px;
    }

    @media only screen and (min-width: 483px) {
      .modal-body {
        padding: 30px;
      }
    }
  }
}
