@import './colors';

.digital-platform-button {
  box-sizing: border-box;
  width: auto;
  border-radius: 40px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  &.primary {
    &.light {
      border: 1px solid $secondary;
      background: map-get($theme-colors, white);
      color: $secondary;

      &:focus {
        border: 1.5px solid map-get($theme-colors, red-shade);
        color: map-get($theme-colors, red-shade);
      }

      &:hover {
        background: $secondary;
        color: map-get($theme-colors, white);
      }
    }

    &.dark {
      border: 0;
      background: map-get($theme-colors, white);
      color: $secondary;

      &:focus {
        color: map-get($theme-colors, red-shade);
      }

      &:hover {
        color: map-get($theme-colors, red-shade);
      }
    }

    &.disabled {
      background: map-get($theme-colors, light-neutral);
      color: map-get($theme-colors, cool-gray-8c);
      border: 2px solid map-get($theme-colors, cool-gray-1c);
      line-height: 20px;

      &:focus,
      &:hover {
        background: map-get($theme-colors, light-neutral);
        color: map-get($theme-colors, cool-gray-8c);
        border: 2px solid map-get($theme-colors, cool-gray-1c);
      }
    }
  }

  &.secondary {
    &.light {
      border: 1px solid $secondary;
      background: map-get($theme-colors, light-neutral);
      color: $secondary;

      &:focus {
        border: 1.5px solid map-get($theme-colors, red-shade);
        color: map-get($theme-colors, red-shade);
      }

      &:hover {
        background: $secondary;
        color: map-get($theme-colors, white);
      }
    }

    &.dark {
      background: map-get($theme-colors, cool-gray-11c);
      border: 1px solid map-get($theme-colors, white);
      color: map-get($theme-colors, white);

      &:focus {
        border: 1px solid map-get($theme-colors, light-neutral);
        color: map-get($theme-colors, light-neutral);
      }

      &:hover {
        background: map-get($theme-colors, white);
        color: map-get($theme-colors, red-shade);
      }
    }

    &.disabled {
      background: map-get($theme-colors, cool-gray-11c);
      color: map-get($theme-colors, cool-gray-1c);
      border: 0;
      line-height: 20px;

      &:focus,
      &:hover {
        background: map-get($theme-colors, cool-gray-11c);
        color: map-get($theme-colors, cool-gray-1c);
        border: 0;
        line-height: 20px;
      }
    }
  }

  &.tertiary {
    &.light {
      border: 1px solid map-get($theme-colors, container-blue);
      background: map-get($theme-colors, white);
      color: map-get($theme-colors, container-blue);

      &:focus {
        border: 1.5px solid map-get($theme-colors, container-blue-shade);
        color: map-get($theme-colors, container-blue-shade);
      }

      &:hover {
        background: map-get($theme-colors, container-blue);
        color: map-get($theme-colors, white);
      }
    }

    &.dark {
      border: 0;
      background: map-get($theme-colors, white);
      color: map-get($theme-colors, container-blue);

      &:focus {
        color: map-get($theme-colors, container-blue-shade);
      }

      &:hover {
        color: map-get($theme-colors, container-blue-shade);
      }
    }

    &.disabled {
      background: map-get($theme-colors, light-neutral);
      color: map-get($theme-colors, cool-gray-8c);
      border: 2px solid map-get($theme-colors, cool-gray-1c);
      line-height: 20px;

      &:focus,
      &:hover {
        background: map-get($theme-colors, light-neutral);
        color: map-get($theme-colors, cool-gray-8c);
        border: 2px solid map-get($theme-colors, cool-gray-1c);
      }
    }
  }

  &.lg {
    padding: 10px 24px;
    height: 44px;
  }

  &.sm {
    padding: 8px 16px;
    height: 40px;
  }
}
