.lookup-account-modal {
  &.modal-dialog.modal-md .modal-body select.placeholder.ng-dirty,
  &.modal-dialog.modal-md .modal-body select.placeholder:not(.ng-pristine) {
    color: #000 !important;
  }

  &.modal-dialog.modal-md .modal-body .select-dropdown-icon {
    font-size: 21px;
    position: absolute;
    right: 10px;
    top: 12px;
    color: #252525;
  }

  &.modal-dialog.modal-md .form-alert .alert.alert-warning {
    padding: 10px;
  }

  &.modal-dialog.modal-md .error-content p {
    font-size: 16px;
    padding: 0 12px;
  }
  .form-alert .error-content p {
    margin-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
  }

  input.form-control + div.form-control-feedback {
    font-size: 18px;
    top: 4px;
  }

  .fa-exclamation-triangle {
    color: #d80025;
  }

  &.modal-dialog.modal-md .fa-exclamation-triangle:before {
    padding: 4px;
  }

  .form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    pointer-events: none;
  }

  .fade.in {
    opacity: 1;
  }

  .fade {
    opacity: 0;
    transition: opacity 0.15s linear;
  }

  .btn {
    border-radius: 23px;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    outline: none;
    padding: 10px 20px;
    text-decoration: none;
    white-space: inherit;
  }

  label,
  label.control-label,
  .control-label.control-label,
  .input-helper {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    text-align: left;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
  }

  .form-group {
    margin-bottom: 15px;
  }

  &.modal-dialog {
    &.modal-md {
      .modal-body {
        padding: 0;
        background-color: transparent;
        z-index: 3;

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';

          &.placeholder {
            color: #999;
            font-size: 18px;
            line-height: inherit;
          }
        }
      }
      form {
        width: 365px;
        margin: auto;
        padding-right: 0;

        button {
          margin: 0 20px;
        }
      }

      .error-alert {
        padding: 0 65px;
      }
    }
  }

  .rsx-location-input__postal-code {
    margin: 0;
    padding: 0;
    width: 100%;

    form {
      padding-right: 0;
      padding-left: 0;
    }
  }

  #lookupform-postalCode {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    font-family: inherit;
    display: block;
    width: 100%;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    box-shadow: inset 0 1px 1px #00000013;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border-radius: 10px;
    color: #252525;
    font-size: 18px;
    height: 44px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 35px;
  }

  #lookupform-previousCompany {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    text-transform: none;
    font-family: inherit;
    display: block;
    width: 100%;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    // box-shadow: inset 0 1px 1px #00000013;
    // transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border-radius: 10px;
    height: 44px;
    padding-right: 35px;
    outline: none;
    -webkit-appearance: none;
    text-indent: 1px;
    color: #999;
    font-size: 18px;
    line-height: inherit;

    &.placeholder {
      display: inline-block;
      min-height: 1em;
      vertical-align: middle;
      cursor: default;
      opacity: 1;
    }
  }

  select.ng-touched.ng-invalid,
  input.ng-touched.ng-invalid,
  textarea.ng-touched.ng-invalid {
    border: 2px #d80025 solid;
  }

  .select-input select.placeholder {
    color: #b4b4b4;
    font-size: 14px;
    line-height: 17px;
  }

  .select-input select.margin-top-0 {
    margin-top: 0;
  }

  .select-input select {
    margin-top: 25px;
  }

  .has-feedback .form-control {
    padding-right: 35px;
  }

  .lookup-account-modal {
    &.modal-dialog.modal-md {
      .modal-header {
        h3 {
          position: relative;
          font-size: 36px;
          z-index: 1;
        }

        i {
          position: relative;
          z-index: 2;
          bottom: 15px;
          left: 15px;
        }

        .fas:not(.fa-stack-2x):before {
          font-size: 1.7em;
        }

        .background-image-republic {
          position: absolute;
          background-position-x: -40167px;
          background-position-y: 4345px;
          width: calc(100% + 40px);
          height: calc(100% + 40px);
          padding: 30px 0 477px 40px;
          margin: -30px 0 -477px -40px;
          z-index: 0;
          border-radius: 10px;
        }
      }

      .modal-body {
        padding: 0;
        background-color: transparent;
        z-index: 3;

        .subhead {
          font-weight: 600;
          font-size: 18px;
          padding: 10px;
          line-height: 24px;
          margin-right: 10px;
        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';

          &.placeholder {
            color: #999;
            font-size: 18px;
            line-height: inherit;

            &.ng-dirty,
            &:not(.ng-pristine) {
              color: #000;
            }
          }

          @media #{$mobile} {
            max-width: 86vw;
          }

          @media #{$mobile-xs} {
            max-width: 86vw;
          }

          @media #{$mobile-xxs} {
            margin-left: -10px;
          }
        }

        .select-dropdown-icon {
          font-size: 21px;
          position: absolute;
          right: 10px;
          top: 12px;

          @media #{$mobile-xxs} {
            margin-right: 0;
          }
        }

        #lookupform-previousAccountNumber {
          @media #{$mobile} {
            max-width: 86vw;
          }

          @media #{$mobile-xs} {
            max-width: 86vw;
          }

          @media #{$mobile-xxs} {
            margin-left: -10px;
          }

          label,
          label.control-label,
          .control-label.control-label {
            width: 100%;
          }

          form {
            padding: 0;
          }

          .rsx-location-input__postal-code {
            margin: 0;
          }
        }

        #lookupform-postalCode {
          @media #{$mobile} {
            max-width: 86vw;
          }

          @media #{$mobile-xxs} {
            margin-left: -10px;
          }
        }

        form {
          width: 365px;
          margin: auto;
        }

        .rsx-location-input__postal-code {
          width: 100%;
        }

        .form-control-feedback {
          @media #{$mobile} {
            margin-right: 2.5rem;
          }

          @media #{$mobile-xs} {
            margin-right: 4rem;
          }

          @media #{$mobile-xxs} {
            margin-right: 8rem;
          }
        }
      }

      .error-alert {
        padding: 0 65px;
      }

      .form-alert {
        .alert.alert-warning {
          padding: 10px;
        }
      }

      .error-content p {
        font-size: 16px;
        padding: 0 12px;
      }

      .modal-content {
        padding: 20px;

        section {
          position: relative;
        }
      }

      .modal-footer {
        border: 0;
      }

      .fa-exclamation-triangle:before {
        padding: 4px;
      }

      form {
        width: 365px;
        margin: auto;

        button {
          margin: 0 20px;

          @media #{$mobile} {
            margin-left: -3.5vw;
            max-width: max-content;
          }

          @media #{$mobile-xs} {
            margin-left: -2.5rem;
          }

          @media #{$mobile-xxs} {
            margin-left: -8rem;
            padding: 12px;
          }
        }

        .form-alert {
          .alert {
            @media #{$mobile-xxs} {
              max-width: 80vw;
            }

            @media #{$mobile} {
              max-width: 84vw;
            }
          }
        }
      }
    }
  }
}
