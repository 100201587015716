.container-lg-modal {
  .btn {
    border-radius: 23px;
  }

  .fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;

    > li {
      text-align: left;
      margin-left: 25px;
      position: relative;
      color: #252525;
    }
  }
}
