@use '@angular/material' as mat;
@import '@angular/material/theming';

@import './styles/spinner';
@import './styles/cms-styles';
@import 'libs/rs-shared/ui-brand/src/brand';
@import 'libs/rs-shared/ui-brand/src/styles/colors';

@include mat.core();

$mat-primarycolor: (
  100: map-get($theme-colors, 'cool-gray-1c'),
  500: map-get($theme-colors, 'cool-gray-1c'),
  700: map-get($theme-colors, 'cool-gray-1c'),
  contrast: (
    100: map-get($theme-colors, 'black'),
    500: map-get($theme-colors, 'black'),
    700: map-get($theme-colors, 'black'),
  ),
);

$mat-accentcolor: (
  100: map-get($theme-colors, 'cool-gray-1c'),
  500: map-get($theme-colors, 'cool-gray-1c'),
  700: map-get($theme-colors, 'cool-gray-1c'),
  contrast: (
    100: map-get($theme-colors, 'black'),
    500: map-get($theme-colors, 'black'),
    700: map-get($theme-colors, 'black'),
  ),
);

$my-theme-primary: mat-palette($mat-primarycolor);
$my-theme-accent: mat-palette($mat-accentcolor);
$open-sans-typography: mat.define-typography-config(
  $font-family: $font-family-base,
);

@include mat.typography-hierarchy($open-sans-typography);

$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-theme-primary,
      accent: $my-theme-accent,
    ),
    typography: $open-sans-typography,
  )
);

@include mat.all-component-themes($my-app-theme);

/* fix the spacing at the bottom caused by adobe(?) pixel */
img[src^="https://secure.adnxs.com"]
{
  position: absolute;
  left: -5000px;
  bottom: 0;
}

/* Fix iOS so it doesn't automatically style inputs and buttons with rounded borders and drop shadows */
textarea,
input.text,
input[type='text'],
input[type='search'],
input[type='button'],
input[type='submit'],
input[type='email'],
input[type='tel'],
input[type='number'],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

.content-box .digital-platform-input.base {
  background-color: $gray-100 !important;
  height: 58px !important;
}

.content-box
  .digital-platform-dropdown-wrapper
  .digital-platform-input-label.base {
  top: -10px;
}

.content-box .digital-platform-dropdown-wrapper .digital-platform-input {
  padding-top: 0;
}

.content-box
  .state-dropdown
  .digital-platform-dropdown-wrapper
  .digital-platform-input {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-right: 27px;
  pointer-events: auto;
}

.content-heading {
  p {
    margin-bottom: 0;
  }
}

.edit-profile {
  .digital-platform-input-label {
    color: map-get($theme-colors, cool-gray-8c);
    left: 22px !important;
  }
  .digital-platform-input {
    color: map-get($theme-colors, black);
  }
}

.confirm-modal {
  .modal-heading {
    font-size: 24px;
    font-weight: 600;
    color: $primary;
    margin: 25px 0 15px;
  }

  .modal-dialog {
    max-width: 580px;
    margin: auto;
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }

  .modal-content {
    border-radius: 10px;
    overflow: hidden;
    --bs-modal-bg: #ffffff;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.25);
  }

  .overlay-bg {
    background: $gray-100;
    width: 420px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    transform: skew(18deg);
    border-radius: 0 50px 0px 0px;
  }
}

.btn-cancel {
  .button__link_secondary {
    font-size: 14px;
  }
}

.btn-18 {
  .button__link_secondary {
    font-size: 18px;
  }
}

.inner-btm p {
  margin-bottom: 0;
}

.inner-inline p {
  display: inline-block;
}

.inner-default-inline p {
  display: inline;
}

.inner-unset p {
  display: unset;
}

.inner-underline p {
  text-decoration: underline;
}

.index {
  z-index: 1;
}

@media (max-width: 575.98px) {
  .primary-footer {
    padding-top: 30px !important;
  }

  .confirm-modal .overlay-bg {
    width: 300px;
  }

  .icon-btn {
    .digital-platform-icon-button {
      padding: 10px 18px;
    }
  }
}
