// Open Sans
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700&display=swap);

// FontAwesome icons
@font-face {
  font-family: 'Font Awesome 5 Brands', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Font Awesome 5 Brands'),
    url(https://pro.fontawesome.com/releases/v5.12.0/webfonts/fa-brands-400.woff2)
      format('woff2');
}

@font-face {
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://pro.fontawesome.com/releases/v5.12.0/webfonts/fa-regular-400.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Font Awesome 5 Pro'),
    url(https://pro.fontawesome.com/releases/v5.12.0/webfonts/fa-solid-900.woff2)
      format('woff2');
}
