@import 'bootstrap/scss/functions';
@import './colors';

// Map Colors to Bootstrap's colors
$bootstrap-theme: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
);

@each $c in map-keys($theme-colors) {
  $color: map-get($theme-colors, $c);
  .text-#{$c},
  .text-color-#{$c} {
    color: $color;
  }
  .bg-#{$c} {
    background-color: $color;
  }
  .btn-#{$c} {
    background-color: $color;
    color: $light;
  }
  .btn-outline-#{$c} {
    border-color: $color;
    color: $color;
  }
  .border-#{$c} {
    border-color: $color;
  }
  .fill-#{$c} {
    fill: $color;
  }
}

@each $c in map-keys($bootstrap-theme) {
  $color: map-get($bootstrap-theme, $c);
  .text {
    &-#{$c} {
      color: $color;
    }
  }
  .link {
    &-#{$c} {
      &,
      &:hover,
      &:focus {
        color: $color;
      }
    }
  }
  .bg-#{$c} {
    background-color: $color;
  }
  .btn-#{$c} {
    background-color: $color;
    color: $light;
  }
  .btn-outline-#{$c} {
    border-color: $color;
    color: $color;
  }
  .fill-#{$c} {
    fill: $color;
  }
}
.debugger {
  color: red;
}
