@import './responsive-config';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@import './functions';

// @responsive
//
// This mixin will enable business-logic responsiveness based
// on the defined breakpoints from the figma designs located at
// https://www.figma.com/file/4Ocn3YbdHDBQtyc6HankpM/RSI-Design-System?t=pOogAQ5yJ7iCWt5a-1
//
// There are 2 ways of using this mixin:
//
// The simple way:
// ```
//   @include responsive(mobile) {
//     ...your mobile scss rules...
//   }
// ```
//
// or using dynamic content:
// ```
//   @include responsive($breakpoint, $variation) {
//     ...your css rules...
//   }
// ```
// Where the variables of your content could be
// - $breakpoint: "mobile", "tablet", or "desktop"
// - $variation: "small" or "large"
//
// and an example of this usage would be:
// ```
//   @include responsive using ($breakpoint, $variation) {
//     @if $breakpoint == mobile and $variation === large {
//       ... your large-mobile scss rules
//     }
//   }
// ```
// This gives the more control of edge-cases using a more
// granular solution.
//
// Note: This mixin will have "mobile-first" approach where
// larger breakpoints will override smaller breakpoints.
// This will compile to @media min-width(...) { ... }
@mixin responsive($breakpoint: '', $variation: small) {
  @if $breakpoint == '' {
    // Mobile
    @include media-breakpoint-up(xs) {
      @content (mobile, small);
    }
    @include media-breakpoint-up(sm) {
      @content (mobile, large);
    }

    // Tablet
    @include media-breakpoint-up(md) {
      @content (tablet, small);
    }
    @include media-breakpoint-up(lg) {
      @content (tablet, large);
    }

    // Desktop
    @include media-breakpoint-up(xl) {
      @content (desktop, small);
    }
    @include media-breakpoint-up(xxl) {
      @content (desktop, large);
    }
  } @else if is-explicit-breakpoint($breakpoint) {
    // Explicit breakpoint
    @include media-breakpoint-up($breakpoint) {
      @content;
    }
  } @else {
    // Mobile
    @if is-mobile($breakpoint) {
      @if $variation == small {
        @include media-breakpoint-up(xs) {
          @content;
        }
      } @else if $variation == large {
        @include media-breakpoint-up(sm) {
          @content;
        }
      }
    }

    // Tablet
    @if is-tablet($breakpoint) {
      @if $variation == small {
        @include media-breakpoint-up(md) {
          @content;
        }
      } @else if $variation == large {
        @include media-breakpoint-up(lg) {
          @content;
        }
      }
    }

    // Desktop
    @if is-desktop($breakpoint) {
      @if $variation == small {
        @include media-breakpoint-up(xl) {
          @content;
        }
      } @else if $variation == large {
        @include media-breakpoint-up(xxl) {
          @content;
        }
      }
    }
  }
}

// This mixin is to use styles only in a specific breakpoint.
@mixin in-breakpoint($breakpoint: '') {
  // Mobile
  @if is-mobile($breakpoint) {
    @include media-breakpoint-down(md) {
      @content;
    }
  }

  // Tablet
  @if is-tablet($breakpoint) {
    @include media-breakpoint-between(md, xl) {
      @content;
    }
  }

  // Desktop
  @if is-desktop($breakpoint) {
    @include media-breakpoint-up(xl) {
      @content;
    }
  }
}

// This mixin is to use styles using a min-width media query.
@mixin min-breakpoint($breakpoint: '') {
  // Mobile
  @if is-mobile($breakpoint) {
    @content;
  }

  // Tablet
  @if is-tablet($breakpoint) {
    @include media-breakpoint-up(md) {
      @content;
    }
  }

  // Desktop
  @if is-desktop($breakpoint) {
    @include media-breakpoint-up(xl) {
      @content;
    }
  }
}

// This mixin is to use styles using a min-width media query.
@mixin max-breakpoint($breakpoint: '') {
  // Mobile
  @if is-mobile($breakpoint) {
    @include media-breakpoint-down(md) {
      @content;
    }
  }

  // Tablet
  @if is-tablet($breakpoint) {
    @include media-breakpoint-down(xl) {
      @content;
    }
  }

  // Desktop
  @if is-desktop($breakpoint) {
    @content;
  }
}

// Sitewide padding to `.container` class based on breakpoint.

@mixin containerPadding {
  // Mobile
  @include media-breakpoint-down(md) {
    padding: {
      left: 20px;
      right: 20px;
    }
  }

  // Tablet
  @include media-breakpoint-up(md) {
    padding: {
      left: 20px;
      right: 20px;
    }
  }

  // Laptop
  @include media-breakpoint-up(lg) {
    padding: {
      left: 40px;
      right: 40px;
    }
  }

  // Desktop
  @include media-breakpoint-up(xl) {
    padding: {
      left: 60px;
      right: 60px;
    }
  }

  // Desktop Extra Large
  @include media-breakpoint-up(xxl) {
    padding: {
      left: 80px;
      right: 80px;
    }
  }
}

.container {
  @include containerPadding;
}
