.select-account-modal {
  .modal-dialog.modal-sm .modal-content {
    min-height: 220px;
    padding: 20px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .account-list {
    .account-item {
      border-bottom: #d9d9d6 1px solid;
      color: #1076ab;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
      padding: 10px 0;
      white-space: nowrap;

      &.current {
        color: rgba(119, 119, 119, 0.75) !important;
        cursor: default;
      }

      > div {
        padding: 0;
      }

      .default {
        color: rgba(119, 119, 119, 0.75);
      }

      a.account-item {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-family: 'Open Sans', arial, sans-serif;
        text-align: left;
        box-sizing: border-box;
        background-color: transparent;
        font-weight: 400;
        text-decoration: underline;
        text-size-adjust: inherit;
        border-bottom: none !important;
        color: #1076ab;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        padding: 0;
        white-space: nowrap;
      }

      &:hover:not(.account-header),
      &:focus:not(.account-header) {
        background-color: #f7f7f8;
      }
    }
  }

  footer .pagination {
    color: #1076ab;
    font-family: 'Open Sans', arial, sans-serif;
    font-weight: 400;
    margin: 0;
  }

  .pagination {
    color: #1076ab;
    font-family: 'Open Sans', arial, sans-serif;
    font-weight: 400;
    display: inline-block;
    padding-left: 0;
    margin: 22px 0;
    border-radius: 4px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .pagination .pagination-first.disabled,
  footer .pagination .pagination-last.disabled,
  .select-account-modal footer .pagination .pagination-next.disabled,
  .select-account-modal footer .pagination .pagination-prev.disabled {
    visibility: hidden;
  }
  .pagination .pagination-first,
  footer .pagination .pagination-last,
  .select-account-modal footer .pagination .pagination-next,
  .select-account-modal footer .pagination .pagination-prev {
    padding-bottom: 0;
    padding-top: 0;
  }

  .pagination > li {
    margin: 0;
    display: inline;
  }

  ul li {
    color: #252525;
    margin-left: 20px;
  }

  footer .pagination .pagination-first a,
  footer .pagination .pagination-last a,
  .select-account-modal footer .pagination .pagination-next a,
  .select-account-modal footer .pagination .pagination-prev a {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    bottom: 6px;
    font-size: 24px;
    font-weight: 700;
  }
  footer .pagination > li > a {
    border: 0;
    color: #1076ab;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .pagination .pagination-first a,
  .pagination .pagination-last a,
  .pagination .pagination-next a,
  .pagination .pagination-prev a {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    bottom: 6px;
    font-size: 24px;
    font-weight: 700;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
  }
  .pagination > li > a {
    border: 0;
    color: #1076ab;
    background-color: #f5f5f6;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.428571429;
    color: #1076ab;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
  }
}
