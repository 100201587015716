@import './typography', './colors';

// General
body {
  font-family: $font-family-base;
  font-weight: $font-regular;
}

b,
strong {
  font-weight: $font-bold;
}

// Headings
.h1,
h1 {
  @include heading-1;
  color: $primary;
}
.h2,
h2 {
  @include heading-2;
  color: $primary;
}
.h3,
h3 {
  @include heading-3;
  color: $primary;
}
.h4,
h4 {
  @include heading-4;
  color: $primary;
}
.h5,
h5 {
  @include heading-5;
  color: $primary;
}
.h6,
h6 {
  @include heading-6;
}
.h7 {
  @include heading-7;
}

// Body
.body {
  &-lg {
    @include body-lg;
  }
  &-md {
    @include body-md;
  }
  &-sm {
    @include body-sm;
  }
  &-xs {
    @include body-xs;
  }
}

// Text
.text {
  &-stat {
    @include text-stat;
  }
  &-error-font {
    @include text-error;
  }
  &-error-md {
    @include text-error-md;
  }
}

// Eyebrow
.eyebrow {
  &-lg {
    @include eyebrow-lg;
  }
  &-sm {
    @include eyebrow-sm;
  }
}

// Subheadline
.subheadline {
  &-lg {
    @include subheadline-lg;
  }
  &-md {
    @include subheadline-md;
  }
  &-sm {
    @include subheadline-sm;
  }
}
