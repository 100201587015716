// These colors are mapped from the Style Guide and are used to configure Bootstrap
// and the color variables provided by the global styles, avoid directly referencing
// these variables as they may change with updated branding.

// Colors from RS Foundational Styles - https://www.figma.com/proto/Tko6du8jt22H24EQyx7pk1/RS-Foundation-Styles-v2.0?page-id=0%3A1&node-id=410%3A2216&viewport=702%2C1301%2C0.47&scaling=min-zoom&starting-point-node-id=410%3A2216&show-proto-sidebar=1&hide-ui=1
@import 'bootstrap/scss/functions';

$theme-colors: (
  // Brand Colors
  container-blue: #004a7c,
  container-blue-shade: #003763,
  red: #d80025,
  red-shade: #c41230,
  light-blue: #05a0e4,
  light-blue-shade: #0090c6,
  white: #ffffff,
  white-shade: #dddfdd,
  sustainability-green: #006341,
  sustainability-green-shade: #00402c,
  orange: #e8800d,
  orange-shade: #e56300,
  sky-blue: #7bbeea,
  sky-blue-shade: #56ace7,
  cloud-blue: #d8e1e9,
  cloud-blue-shade: #c1d6e4,
  ada-link: #0c5b8f,

  // Neutral Colors
  black: #000000,
  dark-gray: #252525,
  cool-gray-11c: #4d4d4f,
  cool-gray-8c: #717172,
  cool-gray-4c: #b0b1b4,
  cool-gray-1c: #d9d9d6,
  light-neutral: #f6f6f6,

  // Status Colors
  success: #05a0e4,
  success-tint: #0090c6,
  error: #d80025,
  error-tint: #c41230
);

// Override bootstrap's gray map
$gray-100: #f6f6f6;
$gray-200: #e8e8e6; //inferred
$gray-300: #d9d9d6;
$gray-400: #b0b1b4;
$gray-500: #919193; // inferred
$gray-600: #717172;
$gray-700: #4d4d4f;
$gray-800: #39393a; // inferred
$gray-900: #252525; // used by bootstrap fot default text color

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);

$primary: map-get($theme-colors, container-blue);
$secondary: map-get($theme-colors, red);
$success: map-get($theme-colors, success);
$info: map-get($theme-colors, sky-blue);
$warning: map-get($theme-colors, orange);
$danger: map-get($theme-colors, red);
$light: map-get($theme-colors, white);
$dark: map-get($theme-colors, container-blue-shade);
$neutral: map-get($theme-colors, light-neutral);
$gray: map-get(
  $map: $theme-colors,
  $key: cool-gray-8c,
);
