@import 'bootstrap/scss/functions';
@import './spacing';

@for $i from 0 through length($spacers) {
  $size: map-get($spacers, $i);
  .w-#{$i} {
    width: $size !important;
  }
  .h-#{$i} {
    height: $size !important;
  }
}
