// Map the branding colors into bootstrap's color variables
@import './styles/colors';
@import './styles/typography';
@import './styles/responsive';
@import './styles/inputs';
@import './styles/dropdowns';
@import './styles/links';

@import './styles/spacing';
@import './styles/borders';
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import './styles/widths';
@import './styles/color-overrides';
@import './styles/responsive-overrides';
@import './styles/aspect-ratios';
@import './styles/typography-overrides';
@import './styles/buttons';
@import './styles/icon-buttons';

@import './styles/modals-override/styles';
